<template>
  <div class="container">
    <section class="top">
      <div class="row">
        <div class="cols-12">
          <h4>Política de Cookies</h4>
          <br />
          <br />
          <p>
            1. Cookies são pequenos arquivos de texto armazenados no seu
            terminal/dispositivo (computador, celular, tablet), assim que você
            acessa e navega em um site.
          </p>
          <p>
            2. O Site EBL Online também utiliza cookies assim que você acessa e
            navega em nosso site.
          </p>
          <p>
            3. Para que você possa compreender melhor quais são as funções dos
            Cookies, veja abaixo cada um dos tipos:
          </p>
          <ol type="a">
            <li>
              cookies estritamente necessários: são necessários para o
              funcionamento do site e são usados apenas para viabilizar o
              fornecimento dos serviços/funcionalidades essenciais a você. Não
              exigem o seu consentimento prévio para que sejam utilizados;
            </li>
            <li>
              cookies de desempenho e analíticos: coletam informações sobre como
              você navega em um site (análise de comportamento) a fim de
              melhorar os serviços oferecidos;
            </li>
            <li>
              cookies de funcionalidade: memorizam as suas escolhas para
              fornecer uma experiência mais personalizada e funcional durante a
              sua navegação no Site EBL Online.
            </li>
            <li>
              cookies de direcionamento e publicidade: são usados para
              direcionar e exibir conteúdo, incluindo anúncios, com base nas
              suas preferências dentro do Site EBL Online.
            </li>
          </ol>
          <p>
            4. Os cookies descritos nos itens “b”, “c” e “d”, acima, exigem o
            seu consentimento prévio, o qual é obtido por meio de um Aviso de
            Cookies assim que você inicia a navegação no Site EBL Online.
          </p>
          <p>
            5. Ao gerenciar quais cookies o Site EBL Online poderá utilizar,
            você deverá estar ciente de que a sua escolha ficará memorizada pelo
            prazo de 01 (um) ano. Após esse período, quando você navegar no Site
            EBL Online novamente, outro Aviso de Cookies será exibido a fim de
            que você possa escolher quais cookies o Site EBL Online poderá
            utilizar.
          </p>
          <p>
            6. É importante que você saiba que a instalação, permanência e
            existência de cookies em seu computador ou dispositivo depende
            exclusivamente da sua vontade. Assim, você poderá removê-los quando
            desejar. Para saber como remover os cookies do seu computador ou
            dispositivo é necessário verificar a seção “Ajuda” (Help) do
            navegador que você utiliza para acessar a internet.
          </p>
          <p>
            7. Caso você desative os cookies, destacamos que talvez não seja
            possível utilizar e aproveitar todos os recursos e funcionalidades
            oferecidos pelo Site EBL Online.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.top {
  padding: 200px 20px 0;
}
</style>
